import { store } from "store";
import { REDUCER_WALLET } from "./reducers/reducerWallet";

export const setWalletConnection = (payload) => {
  store.dispatch({
    type: REDUCER_WALLET.SET_CONNECTION,
    data: payload,
  });
};

export const setWalletProvider = (payload) => {
  store.dispatch({
    type: REDUCER_WALLET.SET_PROVIDER,
    data: payload,
  });
};

export const setWalletAddress = (payload) => {
  store.dispatch({
    type: REDUCER_WALLET.SET_ADDRESS,
    data: payload,
  });
};

export const setWalletDomain = (payload) => {
  store.dispatch({
    type: REDUCER_WALLET.SET_DOMAIN,
    data: payload,
  });
};

export const setWalletMobileUnsupportedWarning = (payload) => {
  store.dispatch({
    type: REDUCER_WALLET.SET_MOBILE_UNSUPPORTED_WARNING,
    data: payload,
  });
};

export const clearWalletData = (payload) => {
  store.dispatch({
    type: REDUCER_WALLET.CLEAR_DATA,
  });
};
