export const REDUCER_WALLET = {
  SET_CONNECTION: "SET_CONNECTION",
  SET_PROVIDER: "SET_PROVIDER",
  SET_ADDRESS: "SET_ADDRESS",
  SET_DOMAIN: "SET_DOMAIN",
  SET_MOBILE_UNSUPPORTED_WARNING: "SET_MOBILE_UNSUPPORTED_WARNING",
  CLEAR_DATA: "CLEAR_DATA",
};

const initialState = {
  connection: null,
  provider: null,
  address: null,
  domain: null,
  mobileUnsupportedWarning: false,
};

const reducerWallet = (state = initialState, { type, data }) => {
  switch (type) {
    case REDUCER_WALLET.SET_CONNECTION:
      return { ...state, connection: data };
    case REDUCER_WALLET.SET_PROVIDER:
      return { ...state, provider: data };
    case REDUCER_WALLET.SET_ADDRESS:
      return { ...state, address: data };
    case REDUCER_WALLET.SET_DOMAIN:
      return { ...state, domain: data };
    case REDUCER_WALLET.SET_MOBILE_UNSUPPORTED_WARNING:
      return { ...state, mobileUnsupportedWarning: data };
    case REDUCER_WALLET.CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducerWallet;
